import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Col, Row, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { loginRequest } from '../../configuration/auth-config';
import './login.css';

const { Title } = Typography;

const Login = () => {
    const { t: translation } = useTranslation(['pages.login', 'common']);
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate('/');
    };

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            navigateToHome();
        }
    });

    const login = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    };

    return (
        <div id="login-content">
            <Row justify='space-around'>
                <Col span={80}><Title level={1}>{translation('title')}</Title></Col>
            </Row>
            <Row justify='space-around'>
                <Button type="primary" style={{ height: '50px', fontSize: '125%' }} onClick={() => login()} block>Login</Button>
            </Row>
            <div id="request-access-region">
                <a id="request-access-link" href='https://bluekc.identitynow.com/' target="_blank" rel="noreferrer noopener">
                    {translation('request-access-link')}
                </a>
            </div>
        </div>
    );
};

export default Login;
