import React, { Suspense, useEffect, useState } from 'react';
import AuthenticationContextProvider from './contexts/authentication-context-provider';
import Unauthorized from './components/errors/unauthorized';
import { useMsal } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import LoadingState from './constants/loading-state';
import { Spin } from 'antd';
import './app.css';
import PrivateRoute from './authorization/authorize-route';
import { useTranslation } from 'react-i18next';
import Login from './components/login/login';

const Home = React.lazy(() => import('./components/home'));
const PayerToPayer = React.lazy(() => import('./components/reporting/payer-to-payer'));
const DesignatedRecordSet = React.lazy(() => import('./components/reporting/designated-record-set'));
const MemberDetails = React.lazy(() => import('./components/member/member-details'));
const CustomLayout = React.lazy(() => import('./components/shared/layout'));
const InternalServer = React.lazy(() => import('./components/errors/internal-server'));
const NotFound = React.lazy(() => import('./components/errors/not-found'));

function useDocumentTitle() {
    const { t: translation } = useTranslation();
    const title = translation('title.app');

    useEffect(() => {
        document.title = title;
    }, [title]);
}

const App = () => {
    const [loading, setLoading] = useState(LoadingState.Loading);
    const { inProgress } = useMsal();
    useDocumentTitle();

    if (inProgress !== InteractionStatus.None && loading === LoadingState.Loading) {
        return (
            <div className="spinner">
                <Spin size="large" tip="Loading..." />
            </div>
        );
    }

    if (loading === LoadingState.Unauthorized) {
        return <Unauthorized />;
    }

    if (loading === LoadingState.Error) {
        return <InternalServer displayHome={false} />;
    }

    return (
        <Suspense
            fallback={
                <div className="spinner">
                    <Spin size="large" tip="Loading..." />
                </div>
            }
        >
            <AuthenticationContextProvider setLoading={setLoading}>
                <Routes>
                    <Route element={<PrivateRoute loading={loading} />}>
                        <Route path="/" element={<CustomLayout />}>
                            <Route index element={<Home />}></Route>
                            <Route path="login" element={<Login />}></Route>
                            <Route path="member" element={<MemberDetails />}></Route>
                            <Route path="report/designated-record" element={<DesignatedRecordSet />}></Route>
                            <Route path="report/payer-to-payer" element={<PayerToPayer />}></Route>
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />}></Route>
                </Routes>
            </AuthenticationContextProvider>
        </Suspense>
    );
};

export default App;
