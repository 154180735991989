import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t: translation } = useTranslation();

    return <Result status="403" title="401" subTitle={translation('errors.pages.unauthorized')} />;
};

export default Unauthorized;
