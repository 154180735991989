import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest, tokenRequest } from '../configuration/auth-config';
import LoadingState from '../constants/loading-state';
import { AuthContext, AuthenticationContext, UserInfo } from './authentication-context';

interface AuthProviderProps {
    children: ReactNode;
    setLoading: (loading: LoadingState) => void;
}

const AuthenticationContextProvider = (props: AuthProviderProps) => {
    const { setLoading } = props;
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const navigateToLogin = () => {
        navigate('/login');
    };
    const navigateToHome = () => {
        navigate('/');
    };

    //Memoizes an anonymous function that provides the context values for use in consuming components.
    //Memoizing will cache values and not recreate the function if the values listed in the array have not changed.
    const values: AuthContext = useMemo(
        () => ({
            userInfo: userInfo,
            accessToken: accessToken,
        }),
        [userInfo, accessToken],
    );

    // Register Callbacks for redirect flow
    instance.handleRedirectPromise()
        .catch((error) => {
            console.log(error);
        });

    const handleTokenResponse = useCallback((response) => {
        //Specific cases require user interaction - acquire token silently won't always work.
        if (!response.accessToken) throw new InteractionRequiredAuthError();

        setAccessToken(response.accessToken);

        setUserInfo({
            username: response.account.idTokenClaims.upn,
            recordAdmin: response.account.idTokenClaims.recordAdmin === '1',
            recordAdmin25: response.account.idTokenClaims.recordAdmin25 === '1',
        });
        setLoading(LoadingState.Done);
    }, []);

    useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length === 0) {
            navigateToLogin();
        }
        else if (isAuthenticated && !accessToken && inProgress === InteractionStatus.None) {
            const account = accounts[0] as AccountInfo;
            instance
                .acquireTokenSilent({
                    ...tokenRequest,
                    account,
                })
                .then(handleTokenResponse)
                .catch((error) => {
                    // fallback to interaction when silent call fails
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenRedirect({
                                ...tokenRequest,
                                account,
                            })
                            .then(handleTokenResponse);
                    } else {
                        setLoading(LoadingState.Unauthorized);
                    }
                });

            const loginTimeout = setTimeout(() => {
                instance.logoutRedirect({
                    postLogoutRedirectUri: "/login"
                });
            }, 1000 * 60 * 15); // 15-minute timeout

            return () => {
                clearTimeout(loginTimeout);
            };
        }
    }, [loginRequest, inProgress, instance, accounts]);

    return <AuthenticationContext.Provider value={values}>{props.children}</AuthenticationContext.Provider>;
};

export default AuthenticationContextProvider;
