import { Configuration } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_MSAL_FLOW,
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_MSAL_AUTHORITY,
        },
    },
    authorityDomain: process.env.REACT_APP_MSAL_AUTHORITY_DOMAIN,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain as string],
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT,
        postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const tokenRequest = {
    scopes: [
        'openid',
        'profile',
        process.env.REACT_APP_MSAL_TOKEN_PATIENT_READ_SCOPE,
        process.env.REACT_APP_MSAL_TOKEN_COVERAGE_READ_SCOPE,
        process.env.REACT_APP_MSAL_TOKEN_EOB_READ_SCOPE
    ] as string[],
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...tokenRequest.scopes] as string[],
};
