import React from 'react';

export interface UserInfo {
    username?: string;
    recordAdmin?: boolean;
    recordAdmin25?: boolean;
}

export interface AuthContext {
    userInfo?: UserInfo;
    accessToken?: string;
}

export const AuthenticationContext = React.createContext<AuthContext | null>(null);
