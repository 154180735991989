import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Forbidden from '../components/errors/forbidden';
import LoadingState from '../constants/loading-state';
import { AuthenticationContext } from '../contexts/authentication-context';

interface PrivateRouteProps {
    loading: LoadingState;
}

//There are no partially restricted UI elements.  If you are not an admin in any form, then you cannot use this app.  All data-specific authorization is handled at the API layer.
const PrivateRoute = (props: PrivateRouteProps) => {
    const userInfo = useContext(AuthenticationContext)?.userInfo;

    if (props.loading !== LoadingState.Done) return <Outlet />;
    return !userInfo?.recordAdmin25 && !userInfo?.recordAdmin ? <Forbidden /> : <Outlet />;
};

export default PrivateRoute;
