export const namespaces = {
    pages: {
        home: "pages.home",
        member: "pages.member.search"
    },
    common: "common",
};

export const languages = {
    en: "en",
};