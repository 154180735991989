import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './configuration/auth-config';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './i18n/i18n';

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MsalProvider instance={pca}>
                <App />
            </MsalProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
