import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const navigate = useNavigate();
    const { t: translation } = useTranslation();

    const navigateHome = () => {
        navigate('/');
    };

    return (
        <Result
            status="403"
            title="403"
            subTitle={translation('errors.pages.forbidden')}
            extra={
                <Button type="primary" onClick={navigateHome}>
                    {translation('buttons.home')}
                </Button>
            }
        />
    );
};

export default Unauthorized;
